import React, {useContext} from "react"
import { css } from '@emotion/css'
import Fade from 'react-reveal/Fade';
import { graphql, StaticQuery } from 'gatsby';
import { LangugaeContext } from "../../context/LanguageProvider";

const Customer = (props) => {
    return <div style={{ margin: '20px 10px', height: props.height ? props.height : '50px', width: props.width ? props.width : 'auto' }}>
        <img src={props.imageUrl} alt="customer" style={{
            height: '100%',
            opacity: props.noOpacity ? 1 : 0.8
        }} />
    </div>
};

const Customer2 = (props) => {
    return <div
        className="customer-box"
        style={{ transform: props.small ? 'scale(0.8)' : null }}
        onClick={() => props.language.constructPath(props.link, props.language)}
    >
        <img src={props.imageUrl} alt="customer" style={{
            height: props.height,
            opacity: props.noOpacity ? 1 : 0.8
        }} />
    </div>
};

function CustomersTemplate (props) {

    const cmsData = props.cmsData?.customers?.edges[0]?.node?.frontmatter ?? {}
    const language = useContext(LangugaeContext);

    return <div className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            max-width: 1200px;
            margin-bottom: ${props.marginBottom ? `50px` : `auto`};

            @media (max-width: 970px) {
                width: auto;   
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }
        `}

    >
        <Fade top duration={2000} delay={800} distance="30px">

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                {cmsData.customerList?.map((customer) => (
                     <Customer2 
                     height={`${customer.size}%`} 
                     imageUrl={customer.logo.childImageSharp.fluid.src} 
                     link={customer.path} small={props.small} 
                     language={language}
                     />
                ))}
            </div>
        </Fade>

    </div>
}

export default function Customers(props) {
    return (
      <StaticQuery
        query={graphql`
        query {
            customers: allMarkdownRemark(filter: 
                {frontmatter: {templateKey: {eq: "customers"}}}) {
                    edges {
                        node {
                            frontmatter {
                                title
                                customerList {
                                    logo {
                                        childImageSharp {
                                        fluid(maxWidth: 2048, quality: 100) {
                                          ...GatsbyImageSharpFluid
                                        }
                                      }
                                    }
                                    path
                                    size
                                }
                            }
                        }
                    }
                },
          }
        `}
        render={(data) => <CustomersTemplate cmsData={data} />}
      />
    );
  }
