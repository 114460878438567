import { css } from '@emotion/css'
import React from "react"
import LightText from "./LightText"

const ProfileImageWithText = (props) => (
    <div
        className={css`
            max-width: 270px;
            text-align: center;
        `}
    >
        <img
            src={props.imageUrl} alt="Daniel Baldwin"

            className={css`
                pointer-events: none;
                width: ${props.small ? `200px` : `300px`}; 
                margin-bottom: 20px;
                border-radius: ${props.rounded ? `150px` : null };
                border-radius: 100%;

                @media (max-width: 1000px) {
                    width: 200px; 
                }
            `}
        />

        <LightText center color={props.color ? props.color : null} bottom="0" bold text={props.name} />
        <LightText center color={props.color ? props.color : null} text={props.title} />
    </div>
)

export default ProfileImageWithText