import React from "react"
import { css } from '@emotion/css'
//import ParticlesBlack from "../particles/particlesBlack"
import { Link as ScrollLink, Element } from 'react-scroll'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import "../../styles/customer-pages.css"
import Section from "./Section"
import SectionTitle from "./SectionTitle"
import Container from "./Container"
import LightText from "./LightText"
import ProfileImageWithText from "./ProfileImageWithText"
import SectionBreak from "./SectionBreak"
import MainButton from "./MainButton"
import DownArrow from "./DownArrow"
import Customers from "./Customers"
import "../../components/blog/blog.css"
import "../../components/blog/blog2.css"

const CasePage = (props) => (
    <React.Fragment>
        {/*<div style={{ width: '100%', position: 'absolute', background: 'transparent' }}>
            <ParticlesBlack />
</div>*/}

        <div
            className="page-wrapper"
            style={{
                display: 'flex',
                flexDirection: 'column',
                //justifyContent: 'center',
                alignItems: 'center',
                background: 'white', //'#788fb1',
                textAlign: 'center',

                //background: 'radial-gradient(circle, rgb(183 234 249) 9%, rgb(169 208 255) 29%, rgb(182 226 255) 100%)',
                //background: 'radial-gradient(circle, rgb(255 255 255) 9%, rgb(235 244 255) 29%, rgb(178 230 255) 100%)',
                background: 'radial-gradient(circle, rgb(255 255 255) 9%, rgb(230 241 255) 29%, rgb(198 236 255) 100%)'
            }}>
            {/*<h1 className="sectionTitle" style={{ width: 'auto' }}>Case med Svea</h1>
            <LightText fontSize="1.4em" color='#5e638d' resize text="En digital assistent är en mjukvarurobot som kan utföra alla typer av arbetsuppgifter som vi människor gör. Den imiterar vårt arbetssätt, jobbar i samma system men betydligt effektivare." />
    */}
            <div style={{ maxWidth: '1000px', width: '100%', padding: '0 30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Fade>
                    <h1 className="font-responsive-adaptation" style={{ fontSize: '3.2em', color: 'var(--darkGray)', color: 'var(--titleDA)' }}>{props.customer}</h1>
                    <div style={{ height: '5px', width: '126px', background: '#ff1eaf' }} />
                    <div style={{ height: '30px' }} />


                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        maxWidth: '1000px',
                    }}>

                        <ProfileImageWithText
                            rounded
                            small
                            imageUrl={props.quoteImage}
                            name={props.quoteName}
                            title={props.quoteRole} />

                        <div
                            className={css`
                                margin-left: 50px;
                                width: 480px;
                                @media (max-width: 1000px) {
                                    text-align: center;
                                    margin-left: 0;
                                }
                        `}>
                            <LightText
                                italic
                                parentsDecideAlignment
                                resize
                                text={props.quoteText} />
                        </div>
                    </div>


                    {/* <h2 style={{ fontSize: '1.2em', color: 'var(--darkGray)', color: 'var(--lightText)', fontWeight: 'lighter' }}>
                        <span style={{ fontWeight: 'bold' }}>Peter Eisner, Group Manager AFT - Svea </span>
                        – “Jag tycker att du ska välja en digital assistent från Zimply. Du kommer att bli förvånad över hur mycket hjälp du kan få. Den är effektiv, den kommer hjälpa dig förbättra dina processer, och du kommer spara väldigt mycket tid.”
                            </h2>*/}



                </Fade>
            </div>

            <ScrollLink to="firstSection" spy={true} smooth={true} duration={800}>
                <DownArrow />
            </ScrollLink>


            <div style={{ height: '50px' }} />
            <MainButton to={props.cms.button.path} text={props.cms.button.text} />
            <div style={{ height: '50px' }} />


            {props.cms.videoLink != "-" ?
                <div className="video_wrapper">
                    <Zoom>
                        <div style={{ padding: '56.25% 0 0 0', position: 'relative', zIndex: 2 }}>
                            <iframe src={props.cms.videoLink} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay; fullscreen; picture-in-picture"></iframe>

                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </Zoom>
                </div>
                :
                <SectionBreak />
            }

        </div>

        <Element name="firstSection" >
            <Section
                extraMargin={props.cms.videoLink != "-" ? "-220px" : "-90px"}
                imageUrl={'/img/blue-halfcircle-long2.png'}
                imageUrlMobile={'/img/blue-halfcircle-long-mobile.png'}
            >

                <div style={{ height: props.cms.videoLink != "-"? '200px' : '100px' }} />

                <Container>
                    {props.children}
                </Container>

            </Section>
        </Element>

        <Section imageUrl={'/img/pink.png'}>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                maxWidth: '1000px',
            }}>

                <ProfileImageWithText 
                color="white" 
                imageUrl={props.cms.pinkQuote.personImage.childImageSharp.fluid.src} 
                title={props.cms.pinkQuote.role} 
                name={props.cms.pinkQuote.personName}  />

                <div
                    className={css`
                        margin-left: 50px;
                        width: 600px;
                        @media (max-width: 1000px) {
                            text-align: center;
                            margin-left: 0;
                        }
                    `}>
                    <LightText color="white" italic parentsDecideAlignment resize text={props.cms.pinkQuote.quote} />
                </div>
            </div>
            <SectionBreak />
        </Section>

        <Section
            imageUrl={'/img/section3-bg.png'}
            imageUrlMobile={'/img/white-section-mobile-end2.png'}
            extraMargin="-45px"
            extraMarginMobile="-50px"
        >
            <SectionTitle text={props.cms.customersTitle} />
        </Section>

        <Section
            extraMargin="-250px"
            extraMarginMobile="-55px"
        >
            <Customers />
            <SectionBreak />
        </Section>
    </React.Fragment>
)

export default CasePage