import React from "react"
import { Link, graphql } from "gatsby"
import { css } from '@emotion/css'

import Layout from "../components/shared/Layout2"
import SEO from "../components/shared/seo"
import SectionTitle from "../components/shared/SectionTitle"
import LightText from "../components/shared/LightText"
import ProfileImageWithText from "../components/shared/ProfileImageWithText"
import SectionBreak from "../components/shared/SectionBreak"
import NavbarNew from "../components/shared/NavbarNew"
import CasePage from "../components/shared/CasePage"
import { LangugaeContext } from "../context/LanguageProvider";

class CaseTemplate extends React.Component {

  static contextType = LangugaeContext;

  render() {

    const svData = this.props.data.svCase.edges
    const enData = this.props.data.enCase.edges
    
    let post = null;

    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      post = this.context.getCorrectPost(svData, enData, window)
    }

    return (post &&
        <Layout>
          <NavbarNew darkMode />
          <SEO 
            title={post.seo.title == "-" ? "" : post.seo.title}
            description={post.seo.descr == "-" ? "" : post.seo.descr}
          />
          <CasePage
              customer={post.displayTitle}
              quoteImage={post.personImage.childImageSharp.fluid.src}
              quoteName={post.personName}
              quoteTitle={post.title}
              quoteRole={post.role}
              quoteText={post.quote}
              videoLink={post.videoLink}
              cms={post}
          >


            {post?.textSummary != "-" ? 
            <React.Fragment>
              <LightText text={post.textSummary ? post.textSummary : null} /><SectionBreak />
            </React.Fragment>
            :
            null
            }
            
            {post?.textTitle != "-" ? <SectionTitle text={post.textTitle} /> : null}
            {post?.textBlocks?.map(block => {
              return (
                <React.Fragment>
                  <LightText text={block.text} />
                  <SectionBreak />
                </React.Fragment>
              )
            })}

            {post.secondVideoUrl == "-" ?
            null
            :
            <iframe title="demo" style={{ width: '100%', height: '500px' }} src="https://player.vimeo.com/video/337537710" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            }

            {post.secondPersonQuote.personName != "-" ? 
            <React.Fragment>
            <SectionBreak />
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                maxWidth: '1000px',
            }}>

                <ProfileImageWithText 
                  small 
                  imageUrl={post.secondPersonQuote.personImage.childImageSharp.fluid.src} 
                  name={post.secondPersonQuote.personName} 
                  title={post.secondPersonQuote.role} 
                />

                <div
                    className={css`
                                margin-left: 50px;
                                width: 480px;
                                @media (max-width: 1000px) {
                                    text-align: center;
                                    margin-left: 0;
                                }
                        `}>
                    <LightText italic parentsDecideAlignment resize text={post.secondPersonQuote.quote} />
                </div>
            </div>
            <SectionBreak />
            </React.Fragment>
            :
            null
            }
            
          </CasePage>
      </Layout>)
    
  }
}

export default CaseTemplate

export const pageQuery = graphql
`query {
    svCase: allMdx(
      filter: {
        frontmatter: {templateKey: {eq: "sv-case"}},
    }, 
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            displayTitle
            seo {
              title
              descr
            }
            quote
            personName
            role
            personImage {
                childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            button {
                text
                path
            }
            videoLink
            textSummary
            textTitle
            textBlocks {
              text
            }
            secondVideoUrl
            secondPersonQuote {
              quote
              personImage {
                childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
              personName
              role
            }
            pinkQuote {
              quote
              personImage {
                childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
              personName
              role
            }
            customersTitle
          }
        }
      }
    },


    enCase: allMdx(
      filter: {
        frontmatter: {templateKey: {eq: "en-case"}},
    }, 
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            displayTitle
            seo {
              title
              descr
            }
            quote
            personName
            role
            personImage {
                childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            button {
                text
                path
            }
            videoLink
            textSummary
            textTitle
            textBlocks {
              text
            }
            secondVideoUrl
            secondPersonQuote {
              quote
              personImage {
                childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
              personName
              role
            }
            pinkQuote {
              quote
              personImage {
                childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
              personName
              role
            }
            customersTitle
          }
        }
      }
    },

}`

/*`
  query CaseBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        quote
        personName
        role
        personImage {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        button {
            text
            path
        }
        videoLink
        textSummary
        textTitle
        textBlocks {
          text
        }
        secondVideoUrl
        secondPersonQuote {
          quote
          personImage {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
          personName
          role
        }
        pinkQuote {
          quote
          personImage {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
          personName
          role
        }
        customersTitle
      }
    }
  }
`
*/